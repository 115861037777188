<template>
    <div class="registration">
        <div class="registration__heading">
            <h1>Реєстрація</h1>
        </div>
        <ul class="registration__nav nav" role="tablist" data-tabs="tabs">
            <li>
                <a href="#entity" data-toggle="tab" class="active">Для Юр. особи</a>
            </li>
            <li class="registration__nav__delimiter"></li>
            <li>
                <a href="#individual" data-toggle="tab">Для Фіз. особи</a>
            </li>
        </ul>

        <div class="registration__content tab-content">
            <div id="individual" role="tabpanel" class="tab-pane fade">
                <registrationIndividual />
            </div>
            <div id="entity" role="tabpanel" class="tab-pane fade in active show">
                <registrationEntity />
            </div>
            <div class="registration__descr">
                <span>Вже є аккаунт? </span>
                <router-link to="/login">Увійти</router-link>
            </div>
        </div>
    </div>
</template>

<script>
   import registrationEntity from '@/components/app/registration/registrationEntity.vue'
   import registrationIndividual from '@/components/app/registration/registrationIndividual.vue'
   import {mapActions} from "vuex";

   export default {
       components: {registrationEntity, registrationIndividual},
       methods: {
           ...mapActions([
               'getActivityTypes'
           ])
       },
       mounted() {
           this.getActivityTypes();
       }
   }

</script>
