<template>
    <form @submit.prevent="submitHandler" autocomplete="off">
        <div class="registration__form-group">
            <input autocomplete="off" v-model.trim="surname" type="text" placeholder="Введіть прізвище" :class="{invalid: $v.surname.$dirty && !$v.surname.required}" />
            <small class="error" v-if="$v.surname.$dirty && !$v.surname.required">Введіть прізвище</small>
        </div>
        <div class="registration__form-group">
            <input autocomplete="off" v-model.trim="name" type="text" placeholder="Введіть ім'я" :class="{invalid: $v.name.$dirty && !$v.name.required}" />
            <small class="error" v-if="$v.name.$dirty && !$v.name.required">Введіть ім'я</small>
        </div>
        <div class="registration__form-group">
            <input autocomplete="off" v-model.trim="patronymic" type="text" placeholder="Введіть по батькові" :class="{invalid: $v.patronymic.$dirty && !$v.patronymic.required}" />
            <small class="error" v-if="$v.patronymic.$dirty && !$v.patronymic.required">Введіть по батькові</small>
        </div>
<!--        <div class="registration__form-group">-->
<!--            <input autocomplete="off" v-model.trim="uin" type="text" placeholder="Введіть ІПН" :class="{invalid: $v.uin.$dirty && !$v.uin.required}" v-mask="'##########'" />-->
<!--            <small class="error" v-if="$v.uin.$dirty && !$v.uin.required">Введіть ІПН</small>-->
<!--        </div>-->
        <div class="registration__form-group">
            <el-select v-model="activityTypeId"
                       class="form-control-el"
                       placeholder="Вид діяльності">
                <el-option v-for="item in activityTypes"
                           :key="item.id"
                           :label="item.name"
                           :value="item.id">
                </el-option>
            </el-select>
        </div>
        <div class="registration__form-group">
            <input autocomplete="off" v-mask="'+38 (###) ###-##-##'" v-model.trim="phone" type="text" placeholder="Номер телефону" :class="{invalid: ($v.phone.$dirty && !$v.phone.required) || ($v.phone.$dirty && !$v.phone.minLength) || ($v.phone.$dirty && !$v.phone.maxLength) || ($v.phone.$dirty && !$v.phone.numeric)}" />
            <small class="error" v-if="$v.phone.$dirty && !$v.phone.required">Введіть номер</small>
            <small class="error" v-if="($v.phone.$dirty && !$v.phone.minLength) || ($v.phone.$dirty && !$v.phone.maxLength)
                || ($v.phone.$dirty && !$v.phone.numeric)">Введіть коректний номер</small>
        </div>
        <div class="registration__form-group">
            <input autocomplete="off" v-model.trim="email" type="text" placeholder="Електронна адреса"
                    :class="{invalid: ($v.email.$dirty && !$v.email.required) || ($v.email.$dirty && !$v.email.email)}" />
            <small class="error" v-if="$v.email.$dirty && !$v.email.required">Введіть свій email</small>
            <small class="error" v-else-if="$v.email.$dirty && !$v.email.email">Введіть коректний email</small>
        </div>
        <div class="registration__form-group">
            <span :class="['showPassword', {active: typePassword === 'text'}]" @click="changeType">
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.0832 7.39531C18.8613 2.71484 15.5027 0.359375 11.0004 0.359375C6.49569 0.359375 3.13944 2.71484 0.917567 7.39766C0.828447 7.58637 0.782227 7.79247 0.782227 8.00117C0.782227 8.20987 0.828447 8.41598 0.917567 8.60469C3.13944 13.2852 6.49804 15.6406 11.0004 15.6406C15.5051 15.6406 18.8613 13.2852 21.0832 8.60234C21.2637 8.22266 21.2637 7.78203 21.0832 7.39531ZM11.0004 13.9531C7.21991 13.9531 4.45194 12.0359 2.4996 8C4.45194 3.96406 7.21991 2.04688 11.0004 2.04688C14.7808 2.04688 17.5488 3.96406 19.5012 8C17.5512 12.0359 14.7832 13.9531 11.0004 13.9531ZM10.9066 3.875C8.6285 3.875 6.78163 5.72188 6.78163 8C6.78163 10.2781 8.6285 12.125 10.9066 12.125C13.1848 12.125 15.0316 10.2781 15.0316 8C15.0316 5.72188 13.1848 3.875 10.9066 3.875ZM10.9066 10.625C9.45585 10.625 8.28163 9.45078 8.28163 8C8.28163 6.54922 9.45585 5.375 10.9066 5.375C12.3574 5.375 13.5316 6.54922 13.5316 8C13.5316 9.45078 12.3574 10.625 10.9066 10.625Z" fill="#C2D2E3"/>
                </svg>
            </span>
            <input autocomplete="off" v-model.trim="password" :type="typePassword" placeholder="Введіть пароль" :class="{invalid: ($v.password.$dirty && !$v.password.required) || ($v.password.$dirty && !$v.password.minLength)}"/>
            <small class="error" v-if="$v.password.$dirty && !$v.password.required">Введіть пароль</small>
            <small class="error" v-else-if="$v.password.$dirty && !$v.password.minLength">Пароль повинен містити мінімум {{$v.password.$params.minLength.min}} символів. Зараз він містить {{password.length}} символів
            </small>
        </div>
        <div class="registration__form-group">
            <span :class="['showPassword', {active: typePassword === 'text'}]" @click="changeType">
                <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M21.0832 7.39531C18.8613 2.71484 15.5027 0.359375 11.0004 0.359375C6.49569 0.359375 3.13944 2.71484 0.917567 7.39766C0.828447 7.58637 0.782227 7.79247 0.782227 8.00117C0.782227 8.20987 0.828447 8.41598 0.917567 8.60469C3.13944 13.2852 6.49804 15.6406 11.0004 15.6406C15.5051 15.6406 18.8613 13.2852 21.0832 8.60234C21.2637 8.22266 21.2637 7.78203 21.0832 7.39531ZM11.0004 13.9531C7.21991 13.9531 4.45194 12.0359 2.4996 8C4.45194 3.96406 7.21991 2.04688 11.0004 2.04688C14.7808 2.04688 17.5488 3.96406 19.5012 8C17.5512 12.0359 14.7832 13.9531 11.0004 13.9531ZM10.9066 3.875C8.6285 3.875 6.78163 5.72188 6.78163 8C6.78163 10.2781 8.6285 12.125 10.9066 12.125C13.1848 12.125 15.0316 10.2781 15.0316 8C15.0316 5.72188 13.1848 3.875 10.9066 3.875ZM10.9066 10.625C9.45585 10.625 8.28163 9.45078 8.28163 8C8.28163 6.54922 9.45585 5.375 10.9066 5.375C12.3574 5.375 13.5316 6.54922 13.5316 8C13.5316 9.45078 12.3574 10.625 10.9066 10.625Z" fill="#C2D2E3"/>
                </svg>
            </span>
            <input autocomplete="off" v-model.trim="passwordReplay" :type="typePassword" placeholder="Повторіть пароль" :class="{invalid: $v.passwordReplay.$error}"/>
            <!--<small class="error" v-if="$v.passwordReplay.$dirty && !$v.passwordReplay.required">Повторите пароль</small>-->
            <small class="error" v-if="$v.passwordReplay.$error">Пароль не збігається</small>
        </div>
        <div class="registration-error" v-if="errorLogin">Користувач з такими даними вже зареєстрований</div>
        <button type="submit" class="submit">Зареєструватися</button>
    </form>
</template>

<script>
import {email, required, minLength, maxLength, sameAs, numeric} from 'vuelidate/lib/validators'
export default {
    name: 'registrations',
    data: () => ({
        email: '',
        password: '',
        typePassword: 'password',
        passwordReplay: '',
        surname: '',
        name: '',
        patronymic: '',
        phone: '0',
        errorLogin: false,
        userType: 0,
        uin: '',
        activityTypeId: null,
    }),
    computed: {
        activityTypes() {
            return this.$store.state.activityTypesStore.list;
        }
    },

    validations: {
        email: {email, required},
        password: {required, minLength: minLength(6)},
        passwordReplay: {sameAsPassword: sameAs('password')},
        name: {required},
        patronymic: {required},
        surname: {required},
        phone: {required, minLength: minLength(19), maxLength: maxLength(19)},
        // uin: {required}
    },

    methods:{
        submitHandler() {
            if(this.$v.$invalid){
                this.$v.$touch()
                return
            }

            let data = {
                email: this.email,
                password: this.password,
                typeId: this.userType,
                phone: this.phone,
                name: this.name,
                surname: this.surname,
                patronymic: this.patronymic,
                // uin: this.uin,
                activityTypeId: this.activityTypeId,
            }
            this.$store.dispatch('REGISTER', data)
            .then(() => this.$router.push('/prime'))
            .catch(err => (console.log(err),this.errorLogin = true))

        },

        changeType () {
            if (this.typePassword === 'password') {
                this.typePassword = 'text'
            } else {
                this.typePassword = 'password'
            }
        }
    },

}
</script>
